.Work-progress {
  display: flex;
  flex-wrap: wrap;
  
  span, button {
    position: relative;
    width: 140px;
    height: 40px;
    font-size: 14px;
    font-weight: $font-bold;
    color: $main-color;
    background-color: $light-grey;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: -1px;
      right: -20px;
      border-left: solid 20px $light-grey;
      border-bottom: solid 20px transparent;
      border-top: solid 20px transparent;
      z-index: 3;
    }

    &::after {
      z-index: 2;
      right: -21px;
      border-left: solid 20px #ebebeb;
      border-bottom: solid 20px transparent;
      border-top: solid 20px transparent;
    }

    &.done {
      color: $white-color;
      background-color: #44a813;

      &::before {
        border-left-color: #44a813;
      }
    }
  }
}
