.Body {
  background-color: $light-color;
  min-height: 100vh;
  padding-bottom: 16px;

  &__container {
    margin-left: 50px;
    min-height: calc(100vh - 16px);
    transition: $transition;
    
    @media screen and (min-width: 936px) {
      margin-left: 260px;
    }
  }
}

.btn {
  padding: 14px 24px;
  color: $white-color;
  font-weight: $font-bold;
  border-radius: 10px;
  
  &-red {
    background-color: $error-color;
  }

  &-green {
    background-color: $success-color;
  }

  &-blue {
    background-color: $main-color;
  }

  &-grey {
    background-color: $dark-grey;
  }

  &-orange {
    background-color: $warning-color;
  }
}

.Heading {
  color: $main-color;
  font-size: 24px;
  font-weight: $font-black;
  margin-bottom: 24px;
}