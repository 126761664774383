.Select-parts {
  margin: 24px 16px;
  padding: 40px;
  background-color: $white-color;
  border-radius: 10px;

  &__filter {
    margin-bottom: 24px;

    h3 {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__group {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;

      div {
        position: relative;
        width: calc(25% - 24px);
        margin: 0 12px;
        display: flex;
        flex-direction: column;
        
        @media screen and (max-width: 960px) {
          width: calc(50% - 24px);
          margin-bottom: 8px;
        }
        
        @media screen and (max-width: 550px) {
          width: calc(100% - 24px);
        }

        label {
          font-size: 14px;
          color: $main-color;
          font-weight: $font-bold;
          margin-bottom: 8px;
        }

        select,
        input {
          background-color: $light-grey;
          padding: 0 16px;
          border-radius: 5px;
          height: 40px;

          &:invalid {
            color: $dark-grey;
            font-style: italic;
          }

          &:invalid option {
            font-style: normal;
            color: black;
          }

          option[disabled] {
            color: $dark-grey;
          }
        }

        img {
          top: unset;
          bottom: 14px;
          transform: none;
        }
      }
    }
  }

  .Table {
    padding: 0;

    &__container {
      width: calc(100% - 1px);
    }
  }

  &__row {
    border: 0;

    td {
      padding: 16px 0 0;

      div {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border: 1px solid #d0dfe5;
        border-right: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        input[type="checkbox"] {
          & + label {
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 5px;
            background-color: $light-grey;

            &::after {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border-radius: 5px;
              background-color: $main-color;
              top: 4px;
              left: 4px;
              transition: $transition;
            }
          }
          
          &:checked + label::after {
            width: 16px;
            height: 16px;
          }
        }

        img {
          width: 75px;
          height: 75px;
          background-color: $light-grey;
          border-radius: 10px;
          object-fit: cover;
          margin: 0 16px;
        }

        span {
          width: calc(100% - 131px);
        }
      }

      &:last-of-type div {
        padding: 8px 32px 8px 0;
        border: 1px solid #d0dfe5;
        border-left: 0;
        border-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        input {
          width: 48px;
          height: 32px;
          margin: 0 8px;
          border: 1px solid #A3B5BC;
          border-radius: 5px;
          text-align: center;
        }

        .btn {
          width: 32px;
          height: 32px;
          padding: 0;
        }
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;

    p {
      margin: 8px 0;
      font-size: 14px;
      font-style: italic;
    }

    div {
      margin: 0 8px;

      .btn {
        margin: 8px;
      }
    }

    .btn-red {
      margin: 8px 0 8px auto;
    }
  }
}
