.Client {
  &__info {
    margin: 24px 24px 40px;

    &__card {
      position: relative;
      background-color: $white-color;
      border-radius: 10px;
      padding: 24px;
      display: flex;

      @media screen and (max-width: 760px) {
        flex-direction: column;
      }

      &__avatar {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        object-fit: cover;
        margin-right: 24px;

        @media screen and (max-width: 760px) {
          margin-right: 0;
          margin-bottom: 24px;
        }

        @media screen and (max-width: 425px) {
          margin-top: 40px;
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        width: calc(100% - 160px);

        @media screen and (max-width: 760px) {
          width: 100%;
        }

        &__row {
          display: flex;

          &:first-of-type {
            @media screen and (min-width: 761px) {
              margin-right: 130px;
            }
          }

          &:not(:first-of-type) {
            margin: 16px 0;
          }

          @media screen and (max-width: 760px) {
            flex-wrap: wrap;
          }

          div {
            width: 100%;

            &:not(:last-of-type) {
              margin-right: 24px;

              @media screen and (max-width: 760px) {
                margin-bottom: 16px;
                margin-right: 0;
              }
            }

            p {
              font-size: 14px;
              color: #a3b5bc;
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              img {
                margin-right: 8px;
              }
            }

            .paragraph {
              padding: 16px;
              background-color: $light-grey;
              border-radius: 5px;
              line-height: 23px;
            }

            span {
              font-weight: $font-bold;
              color: $main-color;
              font-size: 14px;

              &.ml {
                margin-left: 22px;
              }

              &.name {
                font-size: 20px;
              }
            }
          }

          &__images {
            display: flex;
            flex-wrap: wrap;

            img {
              width: 129px;
              height: 129px;
              border-radius: 5px;
              object-fit: cover;
              margin-bottom: 16px;
              margin-right: 16px;
            }
          }
        }
      }

      .btn {
        position: absolute;
        padding: 8px;
        top: 24px;

        @media screen and (min-width: 424px) {
          right: 24px;
        }
      }
    }
  }

  &__cars {
    margin: 0 24px 24px;

    header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .btn {
        height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 24px;
      }
    }

    &__footer {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      p {
        margin: 8px 0;
      }

      &__pages {
        .btn {
          margin: 8px;
        }
      }
    }
  }
}
