.Card {
  &__header {
    margin: 24px;
    display: flex;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: $font-black;
      color: $main-color;
    }

    button {
      width: 100px;
      height: 40px;
      border-radius: 10px;
      background-color: $main-color;
      color: $white-color;
      font-weight: $font-bold;
    }
  }

  &__btns {
    margin-left: 64px;

    button {
      font-weight: $font-bold;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #E2EBEF;
      color: $main-color;
      transition: $transition;
      padding: 12px 24px;

      &.active {
        background-color: $main-color;
        color: $white-color;
      }
    }
  }

  &__section {
    background-color: $white-color;
    border-radius: 10px;
    margin: 0 24px 24px;
    padding: 40px;
  }

  &__info {
    &__client {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &__status {
          display: block;
          margin-bottom: 24px;
          padding: 7px 24px;
          font-size: 12px;
          background-color: $success-color;
          font-style: italic;
          border-radius: 5px;
          color: $white-color;

          &.status-Waiting {
            background-color: $warning-color;
          }
          
          &.status-Closed {
            background-color: $error-color;
          }
        }
      }
    }

    .Client__info__card__details {
      margin-left: 45px;
    }

    hr {
      margin: 24px 0;
    }
  }
}