body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Lato";
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p,
ul,
input,
button,
textarea {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input,
button,
textarea,
select {
  font-family: "Lato";
  border: 0;
  box-shadow: none;
  outline: 0.0001px;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

hr {
  border: 0;
  border-bottom: 1px solid rgba($color: $main-color, $alpha: 0.09);
  margin: 0;
}
