.Car {
  &__back {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-color;
    width: 78px;
    height: 40px;
    color: #a8c5d1;
    margin: 24px 24px 0;
    border-radius: 5px;

    img {
      margin-right: 4px;
    }
  }

  &__info {
    @extend .Client__info;

    &__card {
      @extend .Client__info__card;

      &__avatar {
        @extend .Client__info__card__avatar;
      }
    }
  }

  &__cards {
    @extend .Client__cars;
  }
}
