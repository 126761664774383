.Mechnav {
  @extend .Topnav;
  padding: 0 16px 0 0;

  &__logo {
    @extend .Sidenav__logo !optional;
    width: 260px;
  }

  &__list {
    @extend .Sidenav__list;
    display: flex;
    margin: 0 24px;
  
    &__item {
      @extend .Sidenav__list__item;
  
      &__link {
        @extend .Sidenav__list__item__link;
      }
    }
  }
}
