.Card__section__content {
  display: flex;
  margin: 24px 0 32px;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  .Heading {
    font-size: 18px;
    margin-bottom: 16px;
  }

  &__finish {
    margin: auto;
  }

  &__notes {
    margin-bottom: 24px;

    @media screen and (min-width: 801px) {
      width: calc(100% / 3 * 2 - 24px);
      margin-right: 24px;
      margin-bottom: 0;
    }

    &__box {
      height: 300px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $dark-grey;
        border-radius: 50px;
      }
    }

    &__note {
      display: flex;
      align-items: center;
      border: 1px solid $dark-grey;
      border-radius: 5px;
      background-color: $white-color;
      padding: 8px;
      margin-bottom: 16px;
      margin-right: 4px;

      @media screen and (max-width: 450px) {
        flex-direction: column;
        text-align: center;
      }

      > img {
        width: 32px;
        height: 32px;
        padding: 6px;
        background-color: #7cb8e6;
        border-radius: 5px;
        margin-right: 16px;
      }

      &__text {
        font-weight: 900;
        color: $main-color;

        @media screen and (max-width: 450px) {
          margin: 8px 0;
        }

        @media screen and (min-width: 451px) {
          width: calc(100% - 200px);
        }
      }

      &__date {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: $font-bold;

        @media screen and (min-width: 451px) {
          margin-left: auto;
        }

        img {
          margin-right: 4px;
        }
      }
    }

    &__form {
      margin-top: 16px;

      div {
        position: relative;
        height: 48px;

        &.chat {
          height: 40px;

          input {
            height: 40px;
            border-radius: 20px;
          }
        }

        input {
          position: absolute;
          height: 48px;
          width: 100%;
          background-color: $light-color;
          border-radius: 10px;
          padding: 0 24px;
        }

        button {
          position: absolute;
          width: 24px;
          height: 24px;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
          background-color: transparent;

          img {
            width: 24px;
            height: 24px;
          }

          &.btn-dark {
            background-color: $main-color;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 2px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .error {
        color: $error-color;
        font-size: 12px;
        margin-top: 4px;
        margin-left: 8px;
      }
    }
  }

  &__chat {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 801px) {
      width: calc(100% / 3);
    }

    &__box {
      border: 1px solid $dark-grey;
      border-radius: 15px;
      background-color: $white-color;
      padding: 16px;
      height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 15px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $dark-grey;
        border-radius: 50px;
      }

      &__sender {
        margin-bottom: 16px;
        display: grid;
        grid-template-columns: 1fr 32px;
        column-gap: 4px;

        > img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
          grid-row: 1/3;
          grid-column: 2/3;
        }

        &__time {
          color: $dark-grey;
          font-size: 12px;
          margin-left: auto;
          font-weight: $font-bold;
          margin-bottom: 4px;
          margin-top: 4px;

          img {
            margin-right: 4px;
          }
        }

        &__msg {
          padding: 16px;
          border-radius: 15px;
          background-color: $light-color;
          font-size: 14px;
          font-weight: $font-regular;
          color: $main-color;
        }
      }

      &__reciver {
        @extend .Card__section__content__chat__box__sender;
        grid-template-columns: 32px 1fr;

        > img {
          grid-column: 1/2;
        }

        &__time {
          @extend .Card__section__content__chat__box__sender__time;
          margin-left: 0;
        }

        &__msg {
          @extend .Card__section__content__chat__box__sender__msg;
          background-color: #fffde8;
        }
      }
    }

    &__controls {
      margin-top: 20px;
      margin-left: auto;

      .btn {
        margin: 4px;
      }
    }
  }

  &__parts {
    margin-bottom: 24px;
    width: 100%;

    @media screen and (min-width: 801px) {
      width: calc(100% / 3 * 2 - 24px);
      margin-right: 24px;
      margin-bottom: 0;
    }

    > span {
      display: inline-block;
    }

    .Heading {
      margin-top: 24px;
    }

    &__box {
      height: 240px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 15px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $dark-grey;
        border-radius: 50px;
      }

      &__part {
        @extend .Card__section__content__notes__note;

        > img {
          width: 48px;
          height: 48px;
          background-color: $light-grey;
          border-radius: 10px;
          margin-right: 8px
        }

        &__text {
          @extend .Card__section__content__notes__note__text;
          width: 100%;
          margin-right: 8px;
        }

        button {
          font-size: 12px;
          background: transparent;
          color: $error-color;
          font-weight: $font-bold;
          margin-right: 16px;

          @media screen and (min-width: 451px) {
            margin-left: auto;
          }
        }
      }
    }

    a {
      display: block;
      color: $main-color;
      font-weight: $font-black;
      font-size: 12px;
      margin-top: 24px;
    }
  }
}
