.Mech {
  margin: 24px 16px 8px;
  min-height: calc(100vh - 134px);

  &Home__qr {
    background-color: $white-color;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 40px;
    }
  }

  &__Cards__header {
    margin: 24px 0 16px;
  }
}
