.Register {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;
  overflow: hidden;

  &__img {
    margin-top: 80px;
    margin-right: -200px;
    height: calc(100vh - 80px);

    @media screen and (max-width: 824px) {
      display: none;
    }
  }

  &__form {
    margin-right: 24px;
    margin-left: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 824px) {
      margin: 0 auto;
    }

    &__logo {
      margin-bottom: 32px;
      width: 180px;
      height: 85px;
      object-fit: cover;
    }

    .Heading {
      font-size: 18px;
      margin-bottom: 24px;
    }

    &__desc {
      font-size: 14px;
      color: $dark-grey;
      margin-top: 24px;
      margin-bottom: 32px;
      text-align: center;
      width: 80%;
    }

    &__group {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      width: 295px;

      label {
        margin-bottom: 8px;
        font-size: 12px;
        color: $main-color;
        font-weight: $font-bold;
      }

      input {
        width: 295px;
        height: 40px;
        background-color: $light-grey;
        border-radius: 5px;
        padding: 8px;
      }

      &--code {
        flex-direction: row;

        input {
          width: 50px;
          height: 50px;
          margin: 0 10px;
          text-align: center;
        }
      }

      &__error {
        padding: 8px;
        font-size: 12px;
        color: $error-color;
      }
    }

    &__submit {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        font-size: 12px;
        font-weight: $font-bold;
        color: $main-color;
      }
    }
  }
}
