.Form {
  &__heading {
    color: $main-color;
    display: flex;
    align-items: center;
    height: 86px;
    background-color: $white-color;
    padding: 0 24px;
    font-weight: $font-black;
    font-size: 24px;
  }

  &__container {
    margin: 24px;
    padding: 40px;
    background-color: $white-color;

    &__heading {
      font-weight: $font-black;
      font-size: 18px;
      color: $main-color;
      margin-bottom: 16px;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;

      &__input {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        justify-content: flex-end;

        label {
          font-size: 14px;
          color: $main-color;
          font-weight: $font-bold;
        }

        input,
        select,
        textarea {
          width: 225px;
          height: 40px;
          padding: 0 16px;
          background-color: $light-grey;
          border-radius: 5px;
          margin-top: 8px;
          color: $dark-grey;
        }

        textarea {
          height: 130px;
          width: 355px;
          padding: 8px 16px;
        }

        &--full-width {
          input {
            width: 466px;
          }
        }

        &__videos {
          display: flex;
          flex-wrap: wrap;

          label {
            margin-right: 16px;
            margin-bottom: 16px;
            width: 192px;
            height: 192px;
            margin-top: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.empty {
              border: 1px dashed #A8DDF1;
              border-radius: 5px;
            }

            span {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: $dark-grey;
              font-size: 12px;
              font-weight: $font-bold;

              img {
                width: 20px;
                height: 20px;
                margin-bottom: 10px;
              }
            }

            video {
              width: 192px;
              height: 192px;
              object-fit: cover;
            }
          }
        }

        &__add {
          font-weight: $font-black;
          color: $main-color;
          font-size: 14px;
          width: max-content;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }

    &__errors {
      margin-bottom: 16px;
      p {
        font-size: 12px;
        color: $error-color;
        margin-bottom: 4px;
      }
    }
  }
}
