.Topnav {
  height: 86px;
  background-color: $white-color;
  display: flex;
  align-items: center;
  padding: 0 16px;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    padding: 16px;
  }

  .btn {
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-top: 16px;
    }
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding-left: 16px;
    color: $main-color;
    font-weight: $font-bold;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin-left: 0;
      margin-top: 8px;
    }

    img {
      margin-left: 8px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }
}

::-webkit-input-placeholder {
  font-style: italic;
}
