// Colors
$main-color: #02384e;
$light-color: #eff9fd;
$success-color: #64d52d;
$error-color: #fb7777;
$warning-color: #f1ba3c;
$light-grey: #f3f3f3;
$dark-grey: #7d7d7d;
$white-color: #fff;

// Transition
$transition: all 0.3s ease-in-out;

// Typography
$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$font-black: 900;
