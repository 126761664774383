.Cards {
  &__header {
    margin: 24px 24px 16px;

    &__filter {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .Search {
        width: 231px;
        height: 40px;

        @media screen and (max-width: 500px) {
          width: 100%;
        }

        &__input {
          background-color: $white-color;
          height: 40px;
        }
      }

      .Sort {
        margin-left: auto;

        @media screen and (max-width: 500px) {
          width: 100%;
          margin-top: 16px;
          justify-content: flex-end;
        }
      }
    }
  }

  &__table {
    margin: 0 24px 24px;
  }
}
