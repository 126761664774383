.Table {
  background-color: $white-color;
  padding: 8px;
  border-radius: 10px;
  color: $main-color;
  overflow: auto;

  &__container {
    border-collapse: collapse;
    width: 100%;

    &__header {
      background-color: $light-color;
      height: 40px;
      text-align: left;

      th {
        padding: 0 16px;
      }

      th:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      th:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &__row {
      height: 56px;
      border-bottom: 1px solid rgba($color: $main-color, $alpha: 0.09);
      font-weight: $font-bold;

      td {
        padding: 0 16px;
      }

      a {
        color: $main-color;
      }

      .status {
        width: 120px;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 71px;
          height: 27px;
          margin: 0 auto;
          border-radius: 5px;
          font-style: italic;
          color: $white-color;
          font-weight: $font-regular;
          font-size: 13px;
        }

        &-Open span {
          background-color: $success-color;
        }

        &-Closed span {
          background-color: $error-color;
        }

        &-Waiting span {
          background-color: $warning-color;
        }
      }
    }
  }
}
