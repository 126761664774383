.Search {
  position: relative;
  height: 48px;
  margin-right: 16px;
  width: 400px;
  max-width: calc(100% - 186px);

  @media screen and (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }

  &__input {
    padding: 16px 32px 16px 16px;
    background-color: $light-color;
    border-radius: 11px;
    height: 48px;
    font-size: 14px;
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.Sort {
  display: flex;
  align-items: center;

  span {
    color: $main-color;
    font-weight: $font-bold;
    margin-right: 16px;
  }

  select {
    border-radius: 5px;
    height: 40px;
    padding: 8px;
  }
}