.Sidenav {
  width: 260px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white-color;
  transition: $transition;
  z-index: 1;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    background-color: $main-color;
    transition: $transition;

    img {
      width: 130px;
      height: auto;
    }
  }

  &__list {
    margin: 32px 24px;

    &__item {
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 24px;
      border-radius: 10px;

      &__link {
        display: flex;
        align-items: center;
        font-weight: $font-bold;
        color: $dark-grey;
        background-color: transparent;

        img {
          margin-right: 8px;
        }
      }

      &.active {
        background-color: #eff9fd;

        .Sidenav__list__item__link {
          color: $main-color;
        }
      }
    }
  }

  &__hamburger-menu {
    position: absolute;
    right: -40px;
    top: 24px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background-color: $white-color;
    border-radius: 5px;

    @media screen and (min-width: 921px) {
      display: none;
    }

    span {
      width: 24px;
      height: 3px;
      display: block;
      background: $dark-grey;
      
      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }

  }

  &.hidden {
    @media screen and (max-width: 935px) {
      transform: translateX(-210px);

      .Sidenav__logo {
        transform: translateX(-50px);
      }

      .Sidenav__list {
        transform: translateX(-50px);
      }

      .Sidenav__hamburger-menu {
        right: 5px;
        background-color: $light-color;
      }
    }
  }
}
