.Home {
  &__header {
    margin: 60px 16px 0;
    background-color: $main-color;
    color: $white-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    border-radius: 10px;
    
    @media screen and (min-width: 560px) {
      height: 130px;
    }

    img {
      height: 196px;

      @media screen and (max-width: 450px) {
        display: none;
      }
    }

    &__welcome {
      h1 {
        font-size: 24px;
        font-weight: $font-regular;
        margin-bottom: 8px;

        span {
          color: $warning-color;
        }
      }

      p {
        font-size: 14px;
        color: $light-color;
      }
    }
  }

  &__overview {
    margin-top: 60px;
    padding: 0 16px;

    &__cards {
      display: grid;
      grid-gap: 24px;

      @media screen and (min-width: 761px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (min-width: 470px) and (max-width: 760px) {
        grid-template-columns: repeat(2, 1fr);
      }

      &__card {
        background-color: $white-color;
        border-radius: 10px;
        padding: 16px;
        display: flex;
        align-items: center;

        &__icon {
          width: 48px;
          height: 48px;
          border-radius: 10px;
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          &--clients {
            background-color: rgba($color: $error-color, $alpha: 0.25);
          }

          &--open {
            background-color: rgba($color: $success-color, $alpha: 0.25);
          }

          &--waiting {
            background-color: rgba($color: $warning-color, $alpha: 0.25);
          }

          &--all {
            background-color: rgba($color: $main-color, $alpha: 0.25);
          }
        }

        &__details {
          font-weight: $font-bold;

          h3 {
            font-size: 14px;
            font-style: italic;
            margin-bottom: 4px;
            color: $dark-grey;
          }

          p {
            font-size: 24px;
            color: $main-color;
          }
        }
      }
    }
  }

  &__tables {
    display: flex;
    padding: 32px 16px 0;
    justify-content: space-between;

    @media screen and (max-width: 660px) {
      flex-direction: column;
    }

    h2 {
      margin-bottom: 24px;
    }

    &__table {
      &--cards {
        @media screen and (min-width: 661px) {
          width: calc(60% - 24px);

          &--eng {
            width: 100%;
          }
        }
      }

      &--clients {
        @media screen and (min-width: 661px) {
          width: 40%;
        }

        @media screen and (max-width: 660px) {
          margin-top: 24px;
        }
      }
    }
  }
}
